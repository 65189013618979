<template>
    <b-card no-body>
        <b-card-header>
            <b-row>
                <b-col md="3">
                    <b-form-group description="Дата начала">
                        <date-time-picker
                            locale="ru"
                            id="startDate"
                            :format="null"
                            input-size="sm"
                            position="bottom"
                            :no-header="true"
                            :no-button="true"
                            :no-label="true"
                            :no-clear-button="true"
                            :auto-close="true"
                            :only-date="true"
                            v-model="filters.startDate"
                            formatted="DD.MM.YYYY"
                            :max-date="filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : null"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group description="Дата окончания">
                        <date-time-picker
                            locale="ru"
                            id="'date-to"
                            :format="null"
                            input-size="sm"
                            position="bottom"
                            :no-header="true"
                            :no-label="true"
                            :no-button="true"
                            :no-clear-button="true"
                            :auto-close="true"
                            :only-date="true"
                            v-model="filters.endDate"
                            formatted="DD.MM.YYYY"
                            :min-date="filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : null"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group description="Выберите фильтр штрафов и премий">
                        <b-form-select v-model="filters.salaryBounties"
                                       :options="salaryBountiesOptions"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group description="Выберите фильтр расходов">
                        <b-form-select v-model="filters.generalCosts" :options="generalCostsOptions"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3">
                    <b-form-group description="Номенклатура">
                        <nomenclature-input :disabled="loading" v-model="filters.nomenclature" :clear-button="true"/>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <shift-input :disabled="loading" v-model="filters.shift" class="mr-1"></shift-input>
                </b-col>
                <b-col md="3">
                    <department-picker :disabled="!!loading" v-model="filters.department" :nullable="true"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col">
                    <b-button variant="primary" @click="load()">Обновить</b-button>
                </b-col>
            </b-row>
        </b-card-header>
        <b-card-body class="card-body">
            <bar-chart
                id="bar"
                xkey="date"
                :data="items"
                :labels="['Расходы на оплату труда', 'Количество отгруженной продукции', 'Средняя себестоимость лотка']"
                :ykeys="['salaryCost', 'packCount', 'packCost']"
                :bar-colors="['#ff4444', '#33b5e5', '#ffbb33']"
                grid-text-weight="bold"
                class="mt-3 mb-3"
                resize="true"
                grid="true"
            />
        </b-card-body>
        <b-card-body class="card-body" v-if="items.length > 0">
            <b-table class="mb-0" hover :fields="fields" :items="items" :foot-clone="true" @row-clicked="onRowClicked">
                <template slot="FOOT_date" slot-scope="data">Итого:</template>
                <template slot="FOOT_salaryCost" slot-scope="data">
                    {{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}
                </template>
                <template slot="FOOT_packCount" slot-scope="data">
                    {{ items.reduce((total, item) => total + item[data.field.key], 0) }}
                </template>
                <template slot="FOOT_packCost" slot-scope="data">
                    {{ packCostSummary(items) }}
               </template>
            </b-table>
        </b-card-body>
    </b-card>
</template>

<script>
    import DateTimePicker from "vue-ctk-date-time-picker"
    import { BarChart } from "vue-morris"

    import NomenclatureInput from "../_common/NomenclatureInput";
    import ShiftInput from '../_common/ShiftInput';
    import DepartmentPicker from "../_common/DepartmentPicker";

    import moment from 'moment';
    import {OPERATION_COST_ENDPOINT} from "../../utils/endpoints";
    import OperationCostDetails from "./OperationCostDetails";

    const DETAILS_MODAL_PARAMS = {
        width: 1200,
        height: 'auto',
        adaptive: true,
        scrollable: true,
        name: 'operation-cost-details',
    };

    export default {
        name: "OperationCost",
        components: {
            DateTimePicker,
            NomenclatureInput,
            ShiftInput,
            DepartmentPicker,
            BarChart,
        },
        data() {
            return {
                moment,
                loading: false,
                items: [],
                fields: [
                    {
                        key: 'date',
                        label: 'Дата',
                        thClass: 'text-center',
                        tdClass: 'text-center'
                    },
                    {
                        key: 'salaryCost',
                        label: 'Расходы на оплату труда',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        formatter(value) {
                            return value.toLocaleString('en-US', { useGrouping: true }) + " ₽"
                        }
                    },
                    {
                        key: 'packCount',
                        label: 'Количество отгруженной продукции',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'packCost',
                        label: 'Средняя себестоимость лотка',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        formatter(value) {
                            return value.toLocaleString('en-US', { useGrouping: true }) + " ₽"
                        }
                    }
                ],
                filters: {
                    startDate: moment().subtract(6, "days"),
                    endDate: moment(),
                    salaryBounties: 'withSalaryBounties',
                    generalCosts: 'withSalaryBounties',
                    nomenclature: null,
                    shift: null,
                    department: null,
                },
                salaryBountiesOptions: [
                    {
                        value: 'withSalaryBounties',
                        text: 'С учетом штрафов и премий'
                    },
                    {
                        value: 'withoutSalaryBounties',
                        text: 'Без учета штрафов и премий'
                    }
                ],
                generalCostsOptions: [
                    {
                        value: 'withSalaryBounties',
                        text: 'С учетом общих расходов'
                    },
                    {
                        value: 'withoutSalaryBounties',
                        text: 'Без учета общих расходов'
                    }
                ]
            }
        },
        computed: {
            clearedFilters() {
                return {
                    startDate: moment(this.filters.startDate).format("YYYY-MM-DD"),
                    endDate: moment(this.filters.endDate).format("YYYY-MM-DD"),
                    departmentId: this.filters.department && this.filters.department.id,
                    productionShiftId: this.filters.shift && this.filters.shift.id,
                    nomenclatureId: this.filters.nomenclature && this.filters.nomenclature.id,
                    withSalaryBounties: this.filters.salaryBounties === 'withSalaryBounties',
                    withGeneralCosts: this.filters.generalCosts === 'withSalaryBounties',
                }
            },
        },
        methods: {
            onRowClicked() {
                this.$modal.show(OperationCostDetails, {filters: this.clearedFilters}, DETAILS_MODAL_PARAMS);
            },
            load() {
                this.$http
                    .post(OPERATION_COST_ENDPOINT, this.clearedFilters)
                    .then(response => response.data.data)
                    .then(items => {
                        this.items = items
                    })
                    .catch(() => {
                        this.$toast.error("Не удалось загрузить стоимость операций")
                    })
            },
            packCostSummary(items) {
                let packCountSummary = 0
                let salaryCostSummary = 0
                items.forEach(({ packCount, packCost }) => {
                    packCountSummary += packCount
                    salaryCostSummary += packCount * packCost
                })
                if (packCountSummary > 0) {
                    return (salaryCostSummary / packCountSummary).toFixed(2)
                        .toLocaleString('en-US', { useGrouping: true }) + " ₽"
                }
                return '—'
            }
        },
        mounted() {
            this.load()
            document.title = 'Стоимость операций'
        }
    }
</script>

<style scoped>

</style>
