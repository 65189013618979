<template>
    <div class='all-wrapper'>
        <div class='select-wrapper' :style="{ width: clearButton && internalValue !== null ? '90%' : '100%' }">
            <multiselect
                v-model="internalValue"
                :options="options"
                :multiple="false"
                trackBy="id"
                label="name"
                select-label=""
                selected-label=""
                deselect-label=""
                deselected-label=""
                @input="onChange"
                :max-height="400"
                @search-change="onSearch"
                :show-no-results="false"
                :disabled="disabled"
                placeholder="Выберите номенклатуру"
            >
                <template slot="option" slot-scope="item">
                    <span :style="{'text-decoration': item.option.deactivated ? 'line-through' : ''}">
                        {{ item.option.name }}
                    </span>
                </template>
                <template slot="noOptions">
                    Список пуст
                </template>
            </multiselect>
        </div>
        <div class='button-wrapper' v-if='clearButton && internalValue !== null'>
            <i class='fas fa-times pointer-behavior' @click='clear()'></i>
        </div>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import Multiselect from 'vue-multiselect'
    import { debounce } from 'lodash'
    import { NOMENCLATURES_ENDPOINT } from "../../utils/endpoints";

    const WITH = [
        'measureUnit',
        'type',
    ];

    export default {
        components: { Multiselect },
        props: {
            value: {},
            disabled: {
                type: Boolean,
                default: false
            },
            clearButton: {
                type: Boolean,
                default: false
            },
            exceptTypes: {
                type: Array,
                default: () => [],
            }
        },
        watch: {
            value() {
                this.internalValue = this.value
            }
        },
        data() {
            return {
                internalValue: this.value,
                options: [],
                lastSearch: null
            }
        },
        methods: {
            clear() {
                this.internalValue = null;
                this.onChange();
            },
            onChange() {
                this.$emit( 'input', this.internalValue )
            },
            onSearch( search ) {
                if ( search === this.lastSearch ) {
                    return
                }

                this.lastSearch = search;
                this.options = [];

                if ( !search ) return

                this.search( search, this );
            },
            search: debounce( ( search, vm ) => {
                vm.$http.get( NOMENCLATURES_ENDPOINT, {
                    params: {
                        filters: {
                            search_name: search,
                            except_types: vm.exceptTypes
                        },
                        sortBy: 'name',
                        sortDirection: 'asc',
                        limit: 25,
                        with: WITH,
                        without_loading: true
                    },
                    before( request ) {
                        // abort previous request, if exists
                        if ( this.previousRequest ) {
                            this.previousRequest.abort();
                        }

                        // set previous request on Vue instance
                        this.previousRequest = request;
                    }
                } )
                    .then( response => {
                        vm.options = response.data.data.data;
                    } )
                    .catch(response => this.$root.responseError(response, 'Ошибка получения списка номенклатур'));
            }, 250 )
        }
    }
</script>

<style lang='sass' scoped>
    .all-wrapper
        width: 100%
        display: block

    .select-wrapper
        width: 90%
        display: inline-block

    .button-wrapper
        width: 5%
        text-align: right
        display: inline-block

    .pointer-behavior
        cursor: pointer
</style>
