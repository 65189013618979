<template>
    <b-card-group deck>
        <b-card>
            <b-row>
                <b-col><strong>Дата отгрузки От:</strong></b-col>
                <b-col>{{ filters.startDate }}</b-col>
                <b-col><strong>Дата отгрузки До:</strong></b-col>
                <b-col>{{ filters.endDate }}</b-col>
                <b-col><strong>Плановое значение:</strong></b-col>
                <b-col>
                    <b-form-input type="number" :disabled="true" :value="plannedValue"></b-form-input>
                </b-col>
            </b-row>
            <br>
            <b-row>
                <b-col cols="2"><strong>Номенклатура:</strong></b-col>
                <b-col cols="2"><span>{{ row.item.nomenclatureName }}</span></b-col>
                <b-col cols="2"><strong>Вкл общие расходы:</strong></b-col>
                <b-col cols="1">
                    <b-form-checkbox v-model="filters.withGeneralCosts" :value="true"
                                     :disabled="true"></b-form-checkbox>
                </b-col>
            </b-row>

            <b-table :fields="fields" :items="items" :foot-clone="true">
                <template #cell(deviation)="row">
                    <span v-if="typeof row.value === 'number' && row.value > 0" style="color: #00c851">{{row.value}}</span>
                    <span v-else-if="typeof row.value === 'number' && row.value < 0" style="color: red">{{row.value}}</span>
                    <span v-else>{{ row.value }}</span>
                </template>

                <template #foot(deviation)>
                    <span v-if="deviationSummary(items) > 0" style="color: #00c851">{{deviationSummary(items)}}</span>
                    <span v-if="deviationSummary(items) < 0" style="color: red">{{deviationSummary(items)}}</span>
                </template>

                <template #foot(nomenclatureName)="data">Итого:</template>
                <template
                    #foot(salaryCost)="data"
                >{{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}
                </template>
                <template
                    #foot(packCount)="data"
                >{{ items.reduce((total, item) => total + item[data.field.key], 0) }}
                </template>
                <template
                    #foot(packCost)="data"
                >{{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}
                </template>
            </b-table>
        </b-card>
    </b-card-group>
</template>

<script>
export default {
    name: "OperationCostRowDetails",
    props: ['filters', 'row', 'plannedValue'],
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'date',
                    label: 'Дата отгрузки',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'packCount',
                    label: 'Количество отгруженной продукции',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'packCost',
                    label: 'Средняя себестоимость',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    formatter(value) {
                        return value.toLocaleString('en-US', {useGrouping: true}) + " ₽"
                    }
                },
                {
                    key: 'deviation',
                    label: 'Отклонение от планового значения',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'salaryCost',
                    label: 'Сумма оплаты за операции',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    formatter(value) {
                        return value.toLocaleString('en-US', {useGrouping: true}) + " ₽"
                    }
                },
                {
                    key: 'departmentName',
                    label: 'Подразделение',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'operationName',
                    label: 'Операция',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'actualShiftName',
                    label: 'Дата и смена',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'nomenclatureRatio',
                    label: 'Объем работ',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
            ]
        }
    },
    methods: {
        generateDeviation(val) {
            if (val === 0) {
                return 'Деление на 0';
            }
            return Math.round((this.plannedValue / val - 1) * 100) / 100;
        },
        deviationSummary(items) {
            const itemsWithDeviation = items.filter(item => typeof item.deviation === 'number');
            const result = itemsWithDeviation.reduce((acc, item) => (acc += item.deviation, acc), 0) / itemsWithDeviation.length;
            return Math.round(result * 100) / 100;
        }
    },
    mounted() {
        this.items = this.row.item.extraDetails.map(detail => {
            detail.date = this.row.item.date;
            detail.packCost = detail.salaryCost / this.row.item.packCount;
            detail.packCount = this.row.item.packCount;
            detail.deviation = this.generateDeviation(detail.packCost);

            return detail;
        })
    }
}
</script>

