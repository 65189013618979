<template>
    <b-card-group deck>
        <b-card>
            <b-row>
                <b-col><strong>Дата отгрузки От:</strong></b-col>
                <b-col>{{filters.startDate}}</b-col>
                <b-col><strong>Дата отгрузки До:</strong></b-col>
                <b-col>{{filters.endDate}}</b-col>
                <b-col><strong>Плановое значение:</strong></b-col>
                <b-col><b-form-input type="number" v-model="plannedValue" @input="generateDeviationAll"></b-form-input></b-col>
            </b-row>
            <br>
            <b-row>
                <b-col cols="2"><strong>Вкл общие расходы:</strong></b-col>
                <b-col cols="1">
                    <b-form-checkbox v-model="filters.withGeneralCosts" :value="true" :disabled="true"></b-form-checkbox>
                </b-col>
            </b-row>

            <b-table :fields="fields" :items="items" :foot-clone="true">
                <template #cell(actions)="row">
                    <b-button
                        size="sm"
                        variant="light"
                        @click="onRowDetailsClicked(row)"
                    >Подробнее</b-button>
                </template>

                <template #cell(deviation)="row">
                    <span v-if="typeof row.value === 'number' && row.value > 0" style="color: #00c851">{{row.value}}</span>
                    <span v-else-if="typeof row.value === 'number' && row.value < 0" style="color: red">{{row.value}}</span>
                    <span v-else>{{ row.value }}</span>
                </template>

                <template #foot(date)="data">Итого:</template>
                <template #foot(nomenclatureName)="data"></template>
                <template #foot(salaryCost)="data">
                    {{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}
                </template>
                <template #foot(packCount)="data">
                    {{ items.reduce((total, item) => total + item[data.field.key], 0) }}
                </template>
                <template #foot(packCost)="data">
                    {{ packCostSummary(items) }}
               </template>
                <template #foot(deviation)="data">
                    <span v-if="deviationSummary(items) > 0" style="color: #00c851">{{deviationSummary(items)}}</span>
                    <span v-else-if="deviationSummary(items) < 0" style="color: red">{{deviationSummary(items)}}</span>
                    <span v-else>{{deviationSummary(items)}}</span>
                </template>
            </b-table>
        </b-card>
        <br>
    </b-card-group>
</template>

<script>
    import {OPERATION_COST_DETAILS_ENDPOINT} from "@utils/endpoints";
    import OperationCostRowDetails from "./OperationCostRowDetails";

    export default {
        name: "OperationCostDetails",
        props: ['filters'],
        data() {
            return {
                items: [],
                fields: [
                    {
                        key: 'date',
                        label: 'Дата',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'nomenclatureName',
                        label: 'Наименование продукции',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'salaryCost',
                        label: 'Сумма оплаты за операции',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        formatter(value) {
                            return value.toLocaleString('en-US', { useGrouping: true }) + " ₽"
                        }
                    },
                    {
                        key: 'packCount',
                        label: 'Количество отгруженной продукции',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'packCost',
                        label: 'Средняя себестоимость',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        formatter(value) {
                            if (value > 0) {
                                return value.toLocaleString('en-US', { useGrouping: true }) + " ₽"
                            }
                            return '—'
                        }
                    },
                    {
                        key: 'deviation',
                        label: 'Отклонение от планового значения',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'actions',
                        label: 'Действия',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    }
                ],
                plannedValue: 15,
            }
        },
        methods: {
            load() {
                this.$http
                    .post(OPERATION_COST_DETAILS_ENDPOINT, {...this.filters, withExtraDetails: true})
                    .then(response => response.data.data)
                    .then(items => {
                        this.items = items.map(item => {
                            item.deviation = this.generateDeviation(item.packCost);
                            return item;
                        });
                    })
                    .catch(() => {
                        this.$toast.error("Не удалось загрузить стоимость операций детализации")
                    })
            },
            generateDeviationAll() {
                this.items = this.items.map(item => {
                    item.deviation = this.generateDeviation(item.packCost)
                    return item;
                })
            },
            generateDeviation(val) {
                if (val === 0) {
                    return '—';
                }
                return Math.round((this.plannedValue / val - 1) * 100) / 100;
            },
            onRowDetailsClicked(row) {
                this.$modal.show(OperationCostRowDetails, {filters: this.filters, row, plannedValue: this.plannedValue}, {
                    width: 1100,
                    height: 'auto',
                    adaptive: true,
                    scrollable: true,
                    name: 'operation-cost-row-details',
                });
            },
            packCostSummary(items) {
                let packCountSummary = 0
                let salaryCostSummary = 0
                items.forEach(({ packCount, packCost }) => {
                    packCountSummary += packCount
                    salaryCostSummary += packCount * packCost
                })
                if (packCountSummary > 0) {
                    return (salaryCostSummary / packCountSummary).toFixed(2)
                        .toLocaleString('en-US', { useGrouping: true }) + " ₽"
                }
                return '—'
            },
            deviationSummary(items) {
                let packCountSummary = 0
                let salaryCostSummary = 0
                items.forEach(({ packCount, packCost }) => {
                    packCountSummary += packCount
                    salaryCostSummary += packCount * packCost
                })
                if (packCountSummary > 0 && salaryCostSummary > 0) {
                    return  Math.round((this.plannedValue / (salaryCostSummary / packCountSummary) - 1) * 100) / 100
                }
                return '—'
            }
        },
        mounted() {
            this.load();
        }
    }
</script>
